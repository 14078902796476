
/* Tablet Portrait Only */
@media (min-width: 600px) and (max-width: 899px) {
    .mainSection{
        width: 50vw;
        margin-top: min(10vh , 20px);

    }
    .mainScreen{
        margin-top: min(10vh , 20px);
        flex-direction: column;
    }


    button {
        width: 80%;
    }
}

/* Tablet Portrait */
@media (min-width: 600px) {

}


/* Tablet Landscape only */
@media (min-width: 900px) and (max-width: 1199px) {
}


/* Tablet */
@media (min-width: 900px) {
}

/* Monitors only*/
@media (min-width: 1200px) and (max-width: 1799px) {
}


/* Desktops */
@media (min-width: 1200px) {
}

/* Big Displays */
@media (min-width: 1800px) {
}


/* Phone view */
@media (max-width: 599px) {

    .mainSection{
        width: 80vw;
        margin-top: min(10vh , 20px);

    }
    .mainScreen{
        margin-top: min(10vh , 20px);
        flex-direction: column-reverse;
    }

    button {
        width: 80%;
    }
    .copy p{
        font-size: .8em;
    }
}